/*
* iziToast | v1.2.0
* http://izitoast.marcelodolce.com
* by Marcelo Dolce.
*/
.iziToast-capsule {
    font-size: 0;
    height: 0;
    width: 100%;
    transform: translateZ(0);
    backface-visibility: hidden;
    transition: transform 0.5s cubic-bezier(0.25, 0.8, 0.25, 1), height 0.5s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.iziToast-capsule,
.iziToast-capsule * {
    box-sizing: border-box;
}

.iziToast-overlay {
    display: block;
    position: fixed;
    top: -100px;
    left: 0;
    right: 0;
    bottom: -100px;
    z-index: 997;
}

.iziToast {
    display: inline-block;
    clear: both;
    position: relative;
    font-family: 'Lato', Tahoma, Arial;
    font-size: 14px;
    padding: 8px 45px 9px 0;
    background: rgba(238, 238, 238, 0.9);
    border-color: rgba(238, 238, 238, 0.9);
    width: 100%;
    pointer-events: all;
    cursor: default;
    transform: translateX(0);
    -webkit-touch-callout: none /* iOS Safari */;
    -webkit-user-select: none /* Chrome/Safari/Opera */;
    -khtml-user-select: none /* Konqueror */;
    -moz-user-select: none /* Firefox */;
    -ms-user-select: none /* Internet Explorer/Edge */;
    user-select: none;
    min-height: 54px;
}

.iziToast > .iziToast-progressbar {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    z-index: 1;
    background: rgba(255, 255, 255, 0.2);
}

.iziToast > .iziToast-progressbar > div {
    height: 2px;
    width: 100%;
    background: rgba(0, 0, 0, 0.3);
    border-radius: 0 0 3px 3px;
}

.iziToast.iziToast-balloon:before {
    content: '';
    position: absolute;
    right: 8px;
    left: auto;
    width: 0px;
    height: 0px;
    top: 100%;
    border-right: 0px solid transparent;
    border-left: 15px solid transparent;
    border-top: 10px solid #000;
    border-top-color: inherit;
    border-radius: 0;
}

.iziToast.iziToast-balloon .iziToast-progressbar {
    top: 0;
    bottom: auto;
}

.iziToast.iziToast-balloon > div {
    border-radius: 0 0 0 3px;
}

.iziToast > .iziToast-cover {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    height: 100%;
    margin: 0;
    background-size: 100%;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-color: rgba(0, 0, 0, 0.1);
}

.iziToast > .iziToast-close {
    position: absolute;
    right: 0;
    top: 0;
    border: 0;
    padding: 0;
    opacity: 0.6;
    width: 42px;
    height: 100%;
    background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAJPAAACTwBcGfW0QAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAD3SURBVFiF1ZdtDoMgDEBfdi4PwAX8vLFn0qT7wxantojKupmQmCi8R4tSACpgjC2ICCUbEBa8ingjsU1AXRBeR8aLN64FiknswN8CYefBBDQ3whuFESy7WyQMeC0ipEI0A+0FeBvHUFN8xPaUhAH/iKoWsnXHGegy4J0yxialOfaHJAz4bhRzQzgDvdGnz4GbAonZbCQMuBm1K/kcFu8Mp1N2cFFpsxsMuJqqbIGExGl4loARajU1twskJLLhIsID7+tvUoDnIjTg5T9DPH9EBrz8rxjPzciAl9+O8SxI8CzJ8CxKFfh3ynK8Dyb8wNHM/XDqejx/AtNyPO87tNybAAAAAElFTkSuQmCC") no-repeat 50% 50%;
    background-size: 8px;
    cursor: pointer;
    outline: none;
}

.iziToast > .iziToast-close:hover {
    opacity: 1;
}

.iziToast > .iziToast-body {
    position: relative;
    padding: 0 0 0 10px;
    height: auto;
    min-height: 36px;
    margin: 0 0 0 15px;
    text-align: left;
}

.iziToast > .iziToast-body::after {
    content: "";
    display: table;
    clear: both;
}

.iziToast > .iziToast-body .iziToast-texts {
    margin: 10px 0 0 0;
    display: inline-block;
    float: left;
}

.iziToast > .iziToast-body .iziToast-buttons {
    min-height: 17px;
    float: left;
    margin: 4px -2px;
}

.iziToast > .iziToast-body .iziToast-buttons > a,
.iziToast > .iziToast-body .iziToast-buttons > button,
.iziToast > .iziToast-body .iziToast-buttons > input {
    position: relative;
    display: inline-block;
    margin: 2px;
    border-radius: 2px;
    border: 0;
    padding: 5px 10px;
    font-size: 12px;
    letter-spacing: 0.02em;
    cursor: pointer;
    background: rgba(0, 0, 0, 0.1);
    color: #000;
}

.iziToast > .iziToast-body .iziToast-buttons > a:hover,
.iziToast > .iziToast-body .iziToast-buttons > button:hover,
.iziToast > .iziToast-body .iziToast-buttons > input:hover {
    background: rgba(0, 0, 0, 0.2);
}

.iziToast > .iziToast-body .iziToast-buttons > a:focus,
.iziToast > .iziToast-body .iziToast-buttons > button:focus,
.iziToast > .iziToast-body .iziToast-buttons > input:focus {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.6);
}

.iziToast > .iziToast-body .iziToast-buttons > a:active,
.iziToast > .iziToast-body .iziToast-buttons > button:active,
.iziToast > .iziToast-body .iziToast-buttons > input:active {
    top: 1px;
}

.iziToast > .iziToast-body .iziToast-icon {
    height: 100%;
    position: absolute;
    left: 0;
    top: 50%;
    display: table;
    font-size: 23px;
    line-height: 24px;
    margin-top: -12px;
    color: #000;
}

.iziToast > .iziToast-body .iziToast-icon.ico-info {
    background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAMAAACdt4HsAAAAflBMVEUAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACCtoPsAAAAKXRSTlMA6PsIvDob+OapavVhWRYPrIry2MxGQ97czsOzpJaMcE0qJQOwVtKjfxCVFeIAAAI3SURBVFjDlJPZsoIwEETnCiGyb8q+qmjl/3/wFmGKwjBROS9QWbtnOqDDGPq4MdMkSc0m7gcDDhF4NRdv8NoL4EcMpzoJglPl/KTDz4WW3IdvXEvxkfIKn7BMZb1bFK4yZFqghZ03jk0nG8N5NBwzx9xU5cxAg8fXi20/hDdC316lcA8o7t16eRuQvW1XGd2d2P8QSHQDDbdIII/9CR3lUF+lbucfJy4WfMS64EJPORnrZxtfc2pjJdnbuags3l04TTtJMXrdTph4Pyg4XAjugAJqMDf5Rf+oXx2/qi4u6nipakIi7CsgiuMSEF9IGKg8heQJKkxIfFSUU/egWSwNrS1fPDtLfon8sZOcYUQml1Qv9a3kfwsEUyJEMgFBKzdV8o3Iw9yAjg1jdLQCV4qbd3no8yD2GugaC3oMbF0NYHCpJYSDhNI5N2DAWB4F4z9Aj/04Cna/x7eVAQ17vRjQZPh+G/kddYv0h49yY4NWNDWMMOMUIRYvlTECmrN8pUAjo5RCMn8KoPmbJ/+Appgnk//Sy90GYBCGgm7IAskQ7D9hFKW4ApB1ei3FSYD9PjGAKygAV+ARFYBH5BsVgG9kkBSAQWKUFYBRZpkUgGVinRWAdUZQDABBQdIcAElDVBUAUUXWHQBZx1gMAGMprM0AsLbVXHsA5trZe93/wp3svQ0YNb/jWV3AIOLsMtlznSNOH7JqjOpDVh7z8qCZR10ftvO4nxeOvPLkpSuvfXnxzKtvXr7j+v8C5ii0e71At7cAAAAASUVORK5CYII=") no-repeat 50% 50%;
    background-size: 85%;
    width: 24px;
    height: 24px;
}

.iziToast > .iziToast-body .iziToast-icon.ico-warning {
    background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEQAAABECAMAAAAPzWOAAAAAkFBMVEUAAAAAAAABAAIAAAABAAIAAAMAAAABAAIBAAIBAAIAAAIAAAABAAIAAAABAAICAAICAAIAAAIAAAAAAAAAAAABAAIBAAIAAAMAAAABAAIBAAMBAAECAAIAAAIAAAIAAAABAAIBAAIBAAMBAAIBAAEAAAIAAAMAAAAAAAABAAECAAICAAIAAAIAAAMAAAQAAAE05yNAAAAAL3RSTlMAB+kD7V8Q+PXicwv7I9iYhkAzJxnx01IV5cmnk2xmHfzexsK4eEw5L7Gei39aRw640awAAAHQSURBVFjD7ZfJdoJAEEWJgCiI4oDiPM8m7///LidErRO7sHrY5u7YXLr7vKqu9kTC0HPmo9n8cJbEQOzqqAdAUHeUZACQuTkGDQBoDJwkHZR0XBz9FkpafXuHP0SJ09mGeJLZ5wwlTmcbA0THPmdEK7XPGTG1zxmInn3OiJ19zkB0jSVTKExMHT0wjAwlWzC0fSPHF1gWRpIhWMYm7fYTFcQGlbemf4dFfdTGg0B/KXM8qBU/3wntbq7rSGqvJ9kla6IpueFJet8fxfem5yhykjyOgNaWF1qSGd5JMNNxpNF7SZQaVh5JzLrTCZIEJ1GyEyVyd+pClMjdaSJK5O40giSRu5PfFiVyd1pAksjdKRnrSsbVdbiHrgT7yss315fkVQPLFQrL+4FHeOXKO5YRFEKv5AiFaMlKLlBpJuVCJlC5sJfvCgztru/3NmBYccPgGTxRAzxn1XGEMUf58pXZvjoOsOCgjL08+b53mtfAM/SVsZcjKLtysQZPqIy9HPP3m/3zKItRwT0LyQo8sTr26tcO83DIUMWIJjierHLsJda/tbNBFY0BP/bKtcM8HNIWCK3aYR4OMzgxo5w5EFLOLKDExXAm9gI4E3iAO94/Ct/lKWuM2LMGbgAAAABJRU5ErkJggg==") no-repeat 50% 50%;
    background-size: 85%;
    width: 24px;
    height: 24px;
}

.iziToast > .iziToast-body .iziToast-icon.ico-error {
    background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAMAAACdt4HsAAAAeFBMVEUAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAVyEiIAAAAJ3RSTlMA3BsB98QV8uSyWVUFz7+kcWMM2LuZioBpTUVBNcq2qaibj4d1azLZZYABAAACZElEQVRYw7WX25KCMAyGAxUoFDkpiohnV97/DXeGBtoOUprZ2dyo1K82fxKbwJJVp+KQZ7so2mX5oThVQLKwjDe9YZu4DF3ptAn6rxY0qQPOEq9fNC9ha3y77a22ba24v+9Xbe8v8x03dPOC2/NdvB6xeSreLfGJpnx0TyotKqLm2s7Jd/WO6ivXNp0tCy02R/aFz5VQ5wUPlUL5fIfj5KIlVGU0nWHm/5QtoTVMWY8mzIVu1K9O7XH2JiU/xnOOT39gnUfj+lFHddx4tFjL3/H8jjzaFCy2Rf0c/fdQyQszI8BDR973IyMSKa4krjxAiW/lkRvMP+bKK9WbYS1ASQg8dKjaUGlYPwRe/WoIkz8tiQchH5QAEMv6T0k8MD4mUyWr4E7jAWqZ+xWcMIYkXvlwggJ3IvFK+wIOcpXAo8n8P0COAaXyKH4OsjBuZB4ew0IGu+H1SebhNazsQBbWm8yj+hFuUJB5eMsN0IUXmYendAFFfJB5uEkRMYwxmcd6zDGRtmQePEykAgubymMRFmMxCSIPCRbTuFNN5OGORTjmNGc0Po0m8Uv0gcCry6xUhR2QeLii9tofbEfhz/qvNti+OfPqNm2Mq6105FUMvdT4GPmufMiV8PqBMkc+DdT1bjYYbjzU/ew23VP4n3mLAz4n8Jtv/Ui3ceTT2mzz5o1mZt0gnBpmsdjqRqVlmplcPdqa7X23kL9brdm2t/uBYDPn2+tyu48mtIGD10JTuUrukVrbCFiwDzcHrPjxKt7PW+AZQyT/WESO+1WL7f3o+WLHL2dYMSZsg6dg/z360ofvP4//v1NPzgs28WlWAAAAAElFTkSuQmCC") no-repeat 50% 50%;
    background-size: 80%;
    width: 24px;
    height: 24px;
}

.iziToast > .iziToast-body .iziToast-icon.ico-success {
    background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABABAMAAABYR2ztAAAAIVBMVEUAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAABt0UjBAAAACnRSTlMApAPhIFn82wgGv8mVtwAAAKVJREFUSMft0LEJAkEARNFFFEw1NFJb8CKjAy1AEOzAxNw+bEEEg6nyFjbY4LOzcBwX7S/gwUxoTdIn+Jbv4Lv8bx446+kB6VsBtK0B+wbMCKxrwL33wOrVeeChX28n7KTOTjgoEu6DRSYAgAAAAkAmAIAAAAIACQIkMkACAAgAIACAyECBKAOJuCagTJwSUCaUAEMAABEBRwAAEQFLbCJgO4bW+AZKGnktR+jAFAAAAABJRU5ErkJggg==") no-repeat 50% 50%;
    background-size: 85%;
    width: 24px;
    height: 24px;
}

.iziToast > .iziToast-body .iziToast-icon.ico-question {
    background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAQAAAAAYLlVAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAADdcAAA3XAUIom3gAAAAHdElNRQfhCQkUEhFovxTxAAAEDklEQVRo3s2ZTWgTQRTHf03ipTRUqghNSgsRjHgQrFUQC6JgD1Kak3gQUUoPqRdBglf1oBehBws9Cn4cGk+1SOmh2upBxAYVoeJHrR9tgq0i1Cq0lqYeks7MbpPdmU00/c8hm9n33v/t7Nt5M2+qMEWQI0QIibZKRrQpHvLL2KI2wnQzzBKrDm2RIeKEy01dTYKUI7G1ZRknQXV5yP10kTYgly1NF/5S6duZ8ES+1iZodyaocrjXxE0OFeifYYgp0mRIkwFChAkRJsIxGgrIP+I0n82fvZW5dc/zkss0O2o1c5mX6/TmaDWl77RFe5YkUW3tKEmyFv0lOvXJ/fTYnmCEFuMRbGHEZqVHLyT9DFjUJmkzJl9DG5MWWwM6Llif/gF1nukB6nhgGwUXdFrE+wiURA8QoM9i0zEWWpXQW+ZsyeRrOMuyEo5Fv4gmy4dXPvqcC+pH2VRYaMwy+OWG+iLGCgm0W0Kv9HdvR8ASjmKCXpuK/bxiV/76A/v5UdDIZuKcJGjrnec5KZ7wwsWFOp6xPX/9mt2sqDe7FO+Kf/fXHBPPDWpdXGhTpLvUG9VKwh1xMDDjkvu+cNDFBTk7ptX1QkKZ850m3duu6fcrWxwdaFFyREJ2j4vOpKP6Du6z4uJCv8sYJIVkCnJBGGZaBONO3roY2EqNrSfIPi7SKP4fdXyNUd6I6wbSAHEl33tFLe+FlSsusnK90A0+oEPcuufZgXnOi+u9LrKSJQZQw6LwqBnv2CKsfHORbFbyQhA6xN/pEuihSdj56Co7LWRjPiKie6gkB2LiKuUqK5kiPkLiz1QJ9K1cNXBAMoUCigNpQ9IqDtMI1HKA4/jyvUsaoSyZLA5kjOjDPFZen8Ql5TsvBskUgjciIPSX3QAXC86DT7VWvlEh/xZ+ij9BDVWJ0QL0SbZq6QaFxoLPcXPmBLveLCc4wXdDK6s+6/vwhCSniFLPXW0NJe5UB8zKCsviqpc7vGPVQFcyZbyPwGD+d5ZnxmNWlhG4xSBZZjivjIWHEQgoDkSMjMwTo54569JSE5IpA7EyJSMTyGTUAUFlO1ZKOtaHTMeL1PhYYFTcihmY2cQ5+ullj7EDkiVfVez2sCTz8yiv84djhg7IJVk81xFWJlPdfHBG0flkRC/zQFZ+DSllNtfDdUsOMCliyGX5uOzU3ZhIXFDof4m1gDuKbEx0t2YS25gVGpcMnr/I1kx3c6piB8P8ZoqEwfMX3ZyCXynJTmq/U7NUXqfUzCbWL1wqVKBQUeESzQYoUlW8TAcVL1RCxUu1G6BYXfFyfQ4VPbDI4T8d2WzgQ6sc/vmxnTsqfHCZQzUJxm1h5dxS5Tu6lQgTZ0ipqRVqSwzTbbLHMt+c19iO76tsx/cLZub+Ali+tYC93olEAAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDE3LTA5LTA5VDIwOjE4OjE3KzAyOjAwjKtfjgAAACV0RVh0ZGF0ZTptb2RpZnkAMjAxNy0wOS0wOVQyMDoxODoxNyswMjowMP325zIAAAAZdEVYdFNvZnR3YXJlAHd3dy5pbmtzY2FwZS5vcmeb7jwaAAAAAElFTkSuQmCC") no-repeat 50% 50%;
    background-size: 85%;
    width: 24px;
    height: 24px;
}

.iziToast > .iziToast-body .iziToast-title {
    padding: 0;
    margin: 0;
    line-height: 16px;
    font-size: 14px;
    text-align: left;
    float: left;
    color: #000;
    white-space: normal;
}

.iziToast > .iziToast-body .iziToast-message {
    padding: 0;
    margin: 0 0 10px 0;
    font-size: 14px;
    line-height: 16px;
    text-align: left;
    float: left;
    color: rgba(0, 0, 0, 0.6);
    white-space: normal;
}

.iziToast.iziToast-animateInside .iziToast-title,
.iziToast.iziToast-animateInside .iziToast-message,
.iziToast.iziToast-animateInside .iziToast-icon,
.iziToast.iziToast-animateInside .iziToast-buttons-child {
    opacity: 0;
}

.iziToast-target {
    position: relative;
    width: 100%;
    margin: 0 auto;
}

.iziToast-target .iziToast-capsule {
    overflow: hidden;
}

.iziToast-target .iziToast-capsule:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
}

.iziToast-target .iziToast-capsule .iziToast {
    width: 100%;
    float: left;
}

.iziToast-wrapper {
    z-index: 99999;
    position: fixed;
    width: 100%;
    pointer-events: none;
    display: flex;
    flex-direction: column;
}

.iziToast-wrapper .iziToast.iziToast-balloon:before {
    border-right: 0 solid transparent;
    border-left: 15px solid transparent;
    border-top: 10px solid #000;
    border-top-color: inherit;
    right: 8px;
    left: auto;
}

.iziToast-wrapper-bottomLeft {
    left: 0;
    bottom: 0;
}

.iziToast-wrapper-bottomLeft .iziToast.iziToast-balloon:before {
    border-right: 15px solid transparent;
    border-left: 0 solid transparent;
    right: auto;
    left: 8px;
}

.iziToast-wrapper-bottomRight {
    right: 0;
    bottom: 0;
    text-align: right;
}

.iziToast-wrapper-topLeft {
    left: 0;
    top: 0;
}

.iziToast-wrapper-topLeft .iziToast.iziToast-balloon:before {
    border-right: 15px solid transparent;
    border-left: 0 solid transparent;
    right: auto;
    left: 8px;
}

.iziToast-wrapper-topRight {
    top: 0;
    right: 0;
    text-align: right;
}

.iziToast-wrapper-topCenter {
    top: 0;
    left: 0;
    right: 0;
    text-align: center;
}

.iziToast-wrapper-bottomCenter {
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
}

.iziToast-wrapper-center {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    justify-content: center;
    flex-flow: column;
    align-items: center;
}

.iziToast-rtl {
    direction: rtl;
    padding: 8px 0 9px 45px;
    font-family: Tahoma, 'Lato', Arial;
}

.iziToast-rtl .iziToast-cover {
    left: auto;
    right: 0;
}

.iziToast-rtl .iziToast-close {
    right: auto;
    left: 0;
}

.iziToast-rtl .iziToast-body {
    padding: 0 10px 0 0;
    margin: 0 16px 0 0;
    text-align: right;
}

.iziToast-rtl .iziToast-body .iziToast-buttons,
.iziToast-rtl .iziToast-body .iziToast-texts,
.iziToast-rtl .iziToast-body .iziToast-title,
.iziToast-rtl .iziToast-body .iziToast-message {
    float: right;
    text-align: right;
}

.iziToast-rtl .iziToast-body .iziToast-icon {
    left: auto;
    right: 0;
}

@media only screen and (min-width: 568px) {
    .iziToast-wrapper {
        padding: 10px 15px;
    }

    .iziToast-cover {
        border-radius: 3px 0 0 3px;
    }

    .iziToast {
        margin: 5px 0;
        border-radius: 3px;
        width: auto;
    }

    .iziToast::after {
        content: '';
        z-index: -1;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 3px;
        box-shadow: inset 0 -10px 20px -10px rgba(0, 0, 0, 0.2), inset 0 0 5px rgba(0, 0, 0, 0.1), 0 8px 8px -5px rgba(0, 0, 0, 0.25);
    }

    .iziToast.iziToast-color-dark::after {
        box-shadow: inset 0 -10px 20px -10px rgba(255, 255, 255, 0.3), 0 10px 10px -5px rgba(0, 0, 0, 0.25);
    }

    .iziToast.iziToast-balloon .iziToast-progressbar {
        background: transparent;
    }

    .iziToast.iziToast-balloon::after {
        box-shadow: 0 10px 10px -5px rgba(0, 0, 0, 0.25), inset 0 10px 20px -5px rgba(0, 0, 0, 0.25);
    }

    .iziToast-target .iziToast::after {
        box-shadow: inset 0 -10px 20px -10px rgba(0, 0, 0, 0.2), inset 0 0 5px rgba(0, 0, 0, 0.1);
    }
}

.iziToast.iziToast-theme-dark {
    background: #565c70;
    border-color: #565c70;
}

.iziToast.iziToast-theme-dark .iziToast-title {
    color: #fff;
}

.iziToast.iziToast-theme-dark .iziToast-message {
    color: rgba(255, 255, 255, 0.7);
    font-weight: 300;
}

.iziToast.iziToast-theme-dark .iziToast-close {
    background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAQAAADZc7J/AAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAADdcAAA3XAUIom3gAAAAHdElNRQfgCR4OIQIPSao6AAAAwElEQVRIx72VUQ6EIAwFmz2XB+AConhjzqTJ7JeGKhLYlyx/BGdoBVpjIpMJNjgIZDKTkQHYmYfwmR2AfAqGFBcO2QjXZCd24bEggvd1KBx+xlwoDpYmvnBUUy68DYXD77ESr8WDtYqvxRex7a8oHP4Wo1Mkt5I68Mc+qYqv1h5OsZmZsQ3gj/02h6cO/KEYx29hu3R+VTTwz6D3TymIP1E8RvEiiVdZfEzicxYLiljSxKIqlnW5seitTW6uYnv/Aqh4whX3mEUrAAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDE2LTA5LTMwVDE0OjMzOjAyKzAyOjAwl6RMVgAAACV0RVh0ZGF0ZTptb2RpZnkAMjAxNi0wOS0zMFQxNDozMzowMiswMjowMOb59OoAAAAZdEVYdFNvZnR3YXJlAHd3dy5pbmtzY2FwZS5vcmeb7jwaAAAAAElFTkSuQmCC") no-repeat 50% 50%;
    background-size: 8px;
}

.iziToast.iziToast-theme-dark .iziToast-icon {
    color: #fff;
}

.iziToast.iziToast-theme-dark .iziToast-icon.ico-info {
    background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAMAAACdt4HsAAAAflBMVEUAAAD////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////vroaSAAAAKXRSTlMA6PsIvDob+OapavVhWRYPrIry2MxGQ97czsOzpJaMcE0qJQOwVtKjfxCVFeIAAAI3SURBVFjDlJPZsoIwEETnCiGyb8q+qmjl/3/wFmGKwjBROS9QWbtnOqDDGPq4MdMkSc0m7gcDDhF4NRdv8NoL4EcMpzoJglPl/KTDz4WW3IdvXEvxkfIKn7BMZb1bFK4yZFqghZ03jk0nG8N5NBwzx9xU5cxAg8fXi20/hDdC316lcA8o7t16eRuQvW1XGd2d2P8QSHQDDbdIII/9CR3lUF+lbucfJy4WfMS64EJPORnrZxtfc2pjJdnbuags3l04TTtJMXrdTph4Pyg4XAjugAJqMDf5Rf+oXx2/qi4u6nipakIi7CsgiuMSEF9IGKg8heQJKkxIfFSUU/egWSwNrS1fPDtLfon8sZOcYUQml1Qv9a3kfwsEUyJEMgFBKzdV8o3Iw9yAjg1jdLQCV4qbd3no8yD2GugaC3oMbF0NYHCpJYSDhNI5N2DAWB4F4z9Aj/04Cna/x7eVAQ17vRjQZPh+G/kddYv0h49yY4NWNDWMMOMUIRYvlTECmrN8pUAjo5RCMn8KoPmbJ/+Appgnk//Sy90GYBCGgm7IAskQ7D9hFKW4ApB1ei3FSYD9PjGAKygAV+ARFYBH5BsVgG9kkBSAQWKUFYBRZpkUgGVinRWAdUZQDABBQdIcAElDVBUAUUXWHQBZx1gMAGMprM0AsLbVXHsA5trZe93/wp3svQ0YNb/jWV3AIOLsMtlznSNOH7JqjOpDVh7z8qCZR10ftvO4nxeOvPLkpSuvfXnxzKtvXr7j+v8C5ii0e71At7cAAAAASUVORK5CYII=") no-repeat 50% 50%;
    background-size: 85%;
}

.iziToast.iziToast-theme-dark .iziToast-icon.ico-warning {
    background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEQAAABECAMAAAAPzWOAAAAAllBMVEUAAAD////+//3+//3+//3///////z+//3+//3+//3////////////9//3////+//39//3///3////////////+//3+//39//3///z+//z+//7///3///3///3///3////////+//3+//3+//3+//z+//3+//7///3///z////////+//79//3///3///z///v+//3///+trXouAAAAMHRSTlMAB+j87RBf+PXiCwQClSPYhkAzJxnx05tSyadzcmxmHRbp5d7Gwrh4TDkvsYt/WkdQzCITAAAB1UlEQVRYw+3XaXKCQBCGYSIIighoxCVqNJrEPfly/8vFImKXduNsf/Mc4K1y7FnwlMLQc/bUbj85R6bA1LXRDICg6RjJcZa7NQYtnLUGTpERSiOXxrOPkv9s30iGKDmtbYir3H7OUHJa2ylAuvZzRvzUfs7Ii/2cgfTt54x82s8ZSM848gJmYtroQzA2jHwA+LkBIEuMGt+QIng1igzlyMrkuP2CyOi47axRaYTL5jhDJehoR+aovC29s3iIyly3Eb+hRCvZo2qsGTnhKr2cLDS+J73GsqBI9W80UCmWWpEuhIjh6ZRGjyNRarjzKGJ2Ou2himCvjHwqI+rTqQdlRH06TZQR9ek0hiqiPp06mV4ke7QPX6ERUZxO8Uo3sqrfhxvoRrCpvXwL/UjR9GRHMIvLgke4d5QbiwhM6JV2YKKF4vIl7XIBkwm4keryJVmvk/TfwcmPwQNkUQuyA2/sYGwnXL7GPu4bW1jYsmevrNj09/MGZMOEPXslQVqO8hqykD17JfPHP/bmo2yGGpdZiH3IZvzZa7B3+IdDjjpjesHJcvbs5dZ/e+cddVoDdvlq7x12Nac+iN7e4R8OXTjp0pw5CGnOLNDEzeBs5gVwFniAO+8f8wvfeXP2hyqnmwAAAABJRU5ErkJggg==") no-repeat 50% 50%;
    background-size: 85%;
}

.iziToast.iziToast-theme-dark .iziToast-icon.ico-error {
    background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAMAAACdt4HsAAAAeFBMVEUAAAD////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////GqOSsAAAAJ3RSTlMA3BsB98QV8uSyWVUFz6RxYwzYvbupmYqAaU1FQTXKv7abj4d1azKNUit3AAACZElEQVRYw7WXaZOCMAyGw30UORRQBLxX/v8/3BkaWjrY2szO5otKfGrzJrEp6Kw6F8f8sI+i/SE/FucKSBaWiT8p5idlaEtnXTB9tKDLLHAvdSatOan3je93k9F2vRF36+mr1a6eH2NFNydoHq/ieU/UXcWjjk9XykdNWq2ywtp4tXL6Wb2T/MqtzzZutsrNyfvA51KoQROhVCjfrnASIRpSVUZiD5v4RbWExjRdJzSmOsZFvzYz59kRSr6V5zE+/QELHkNdb3VRx45HS1b1u+zfkkcbRAZ3qJ9l/A4qefHUDMShJe+6kZKJDD2pLQ9Q4lu+5Q7rz7Plperd7AtQEgIPI6o2dxr2D4GXvxqCiKcn8cD4gxIAEt7/GYkHL16KqeJd0NB4gJbXfgVnzCGJlzGcocCVSLzUvoAj9xJ4NF7/R8gxoVQexc/hgBpSebjPjgPs59cHmYfn7NkDb6wXmUf1I1ygIPPw4gtgCE8yDw8eAop4J/PQcBExjQmZx37MsZB2ZB4cLKQCG5vKYxMWSzMxIg8pNtOyUkvkocEmXGo69mh8FgnxS4yBwMvDrJSNHZB4uC3ayz/YkcIP4lflwVIT+OU07ZSjrbTkZQ6dTPkYubZ8GC/Cqxu6WvJZII93dcCw46GdNqdpTeF/tiMOuDGB9z/NI6NvyWetGPM0g+bVNeovBmamHXWj0nCbEaGeTMN2PWrqd6cM26ZxP2DeJvj+ph/30Zi/GmRbtlK5SptI+nwGGnvH6gUruT+L16MJHF+58rwNIifTV0vM8+hwMeOXAb6Yx0wXT+b999WXfvn+8/X/F7fWzjdTord5AAAAAElFTkSuQmCC") no-repeat 50% 50%;
    background-size: 80%;
}

.iziToast.iziToast-theme-dark .iziToast-icon.ico-success {
    background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABABAMAAABYR2ztAAAAIVBMVEUAAAD////////////////////////////////////////PIev5AAAACnRSTlMApAPhIFn82wgGv8mVtwAAAKVJREFUSMft0LEJAkEARNFFFEw1NFJb8CKjAy1AEOzAxNw+bEEEg6nyFjbY4LOzcBwX7S/gwUxoTdIn+Jbv4Lv8bx446+kB6VsBtK0B+wbMCKxrwL33wOrVeeChX28n7KTOTjgoEu6DRSYAgAAAAkAmAIAAAAIACQIkMkACAAgAIACAyECBKAOJuCagTJwSUCaUAEMAABEBRwAAEQFLbCJgO4bW+AZKGnktR+jAFAAAAABJRU5ErkJggg==") no-repeat 50% 50%;
    background-size: 85%;
}

.iziToast.iziToast-theme-dark .iziToast-icon.ico-question {
    background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAQAAAAAYLlVAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAADdcAAA3XAUIom3gAAAAHdElNRQfhCQkUEg18vki+AAAETUlEQVRo3s1ZTWhbRxD+VlIuxsLFCYVIIQYVopBDoK5bKDWUBupDMNbJ5FBKg/FBziUQdE9yaC+FHBrwsdCfQ9RTGoLxwWl+DqHEojUFFydxnB9bInZDqOsErBrr6yGvs/ueX97bldTKo4Pe7puZb3Z33s7srIIjMY1jyCEjP6ImvyX8pF64arSHznKC06wzijY5xSKz7YbuYokV2lODsyyxqz3gSY6z6gCuqcpxJluFH+Z8U+D/0jyHoxFUBHgfvsGHIS9WMIUlVFFDFTUAGWSRQRY5HMeBEP6b+Ew9dh/7INd2jGeO59kfKdXP85zbIbfGQVf4sYC3N1hm3lo6zzIbPvk6x+zBk7wQGMEMB5xncIAzAS0XrFySSV72iS1yyBVcdA1x0afrsoUJgdFfY2+z8ADAXl7zz0KcwJiPfZKpVuABgClO+nRG+QIHDdfb4qlWwUXvKW4Z7vi6L4J9vg+vbfCeCeZH2RfOdMOc/HbCA4BvIW6EMQz7XK/ltd+hP+VzR9mgva2YSfyGI17fA7ynnocqeQNFfIJ0oHsdv6CC2+rXGBN6cQdveY3fcVRtmy/HDete+93zy8jA8zV7YkwYMrjHzRddRsCdiVCwwmh6wg9iTNC7Y9XIF1iS7kbUpsvvGEdPuTfSgAEjRpR096x0liPFD/Eqt2NMuBQzB2XhrACAApjFsuQFh9XdGAX70B3oSuNdnMVBaX+sopYxjwVpHFBVACyKTXNoktjD+6Ll8xhenS9MAAkAI/Lux2YNUOs4I413Ypg1SgEAu7kpFvWjaeJe0fJHDGe/cNaZBkekudw8PMA+0fMwlndZeAsJ5KR/qhUDUJCnSiyvRsolkJHGUgvjH8QXDgZopEzKMKDqCKrwEQ4C6MH7GEXC665buLJG8hlQc4LP4paxfJrOqYVYYY2UARfEIazTbgDg2dB98GebzJd54b8L/iWNdLyooeR6CHyZ+6xk0yKxkYg6nEVSUG4VJ9QJ9cxRCxO+9WiOyvgUeexXP1hLGH5nGuBWVtiSp4vqe3VP0UFWI9Wan4Er3v8q7jjPWVtm4FtcQQMrOKO2nOQCM5AyDMi56FDrKHA/1nyppS1ppBpYaE8wciEjGI2AaeM41kI4doDX4XiT3Qm1gevyruCgZg9P8xIv8m1nCzTKq6oiJ9xTMiZ505P5m8cdZ0CnZMVXHVljM7WMBzxpyDxygtdxoCEFTaMIWbZU85UvBjgUMYy0fBaAF8V1Lj9qWQ1aMZ5f4k9r+AGMSkMP1vZoZih6k6sicc5h/OFHM9vDqU/VIU7zJZdYYsKGH4g4nAJMGiXZRds1pVMoZ69RM5vfkbh0qkBhsnS2RLMLilQdL9MBHS9UAh0v1e6CYnXHy/WeeCcvLDwl/9OVze69tPKM+M+v7eJN6OzFpWdEF0ucDbhVNFXadnVrmJFlkVNGTS2M6pzmhMvltfPhnN2B63sVuL7fcNP3D1TSk2ihosPrAAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDE3LTA5LTA5VDIwOjE4OjEzKzAyOjAweOR7nQAAACV0RVh0ZGF0ZTptb2RpZnkAMjAxNy0wOS0wOVQyMDoxODoxMyswMjowMAm5wyEAAAAZdEVYdFNvZnR3YXJlAHd3dy5pbmtzY2FwZS5vcmeb7jwaAAAAAElFTkSuQmCC") no-repeat 50% 50%;
    background-size: 85%;
}

.iziToast.iziToast-theme-dark .iziToast-buttons > a,
.iziToast.iziToast-theme-dark .iziToast-buttons > button,
.iziToast.iziToast-theme-dark .iziToast-buttons > input {
    color: #fff;
    background: rgba(255, 255, 255, 0.1);
}

.iziToast.iziToast-theme-dark .iziToast-buttons > a:hover,
.iziToast.iziToast-theme-dark .iziToast-buttons > button:hover,
.iziToast.iziToast-theme-dark .iziToast-buttons > input:hover {
    background: rgba(255, 255, 255, 0.2);
}

.iziToast.iziToast-theme-dark .iziToast-buttons > a:focus,
.iziToast.iziToast-theme-dark .iziToast-buttons > button:focus,
.iziToast.iziToast-theme-dark .iziToast-buttons > input:focus {
    box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.6);
}

.iziToast.iziToast-color-red {
    background: rgba(255, 175, 180, 0.9);
    border-color: rgba(255, 175, 180, 0.9);
}

.iziToast.iziToast-color-orange {
    background: rgba(255, 207, 165, 0.9);
    border-color: rgba(255, 207, 165, 0.9);
}

.iziToast.iziToast-color-yellow {
    background: rgba(255, 249, 178, 0.9);
    border-color: rgba(255, 249, 178, 0.9);
}

.iziToast.iziToast-color-blue {
    background: rgba(157, 222, 255, 0.9);
    border-color: rgba(157, 222, 255, 0.9);
}

.iziToast.iziToast-color-green {
    background: rgba(166, 239, 184, 0.9);
    border-color: rgba(166, 239, 184, 0.9);
}

.iziToast.iziToast-layout2 .iziToast-body .iziToast-message {
    width: 100%;
}

.iziToast.iziToast-layout3 {
    border-radius: 2px;
}

.iziToast.iziToast-layout3::after {
    display: none;
}

.iziToast.revealIn,
.iziToast .revealIn {
    -webkit-animation: iziT-revealIn 1s cubic-bezier(0.25, 1.6, 0.25, 1) both;
    -moz-animation: iziT-revealIn 1s cubic-bezier(0.25, 1.6, 0.25, 1) both;
    animation: iziT-revealIn 1s cubic-bezier(0.25, 1.6, 0.25, 1) both;
}

.iziToast.slideIn,
.iziToast .slideIn {
    -webkit-animation: iziT-slideIn 1s cubic-bezier(0.16, 0.81, 0.32, 1) both;
    -moz-animation: iziT-slideIn 1s cubic-bezier(0.16, 0.81, 0.32, 1) both;
    animation: iziT-slideIn 1s cubic-bezier(0.16, 0.81, 0.32, 1) both;
}

.iziToast.bounceInLeft {
    -webkit-animation: iziT-bounceInLeft 0.7s ease-in-out both;
    animation: iziT-bounceInLeft 0.7s ease-in-out both;
}

.iziToast.bounceInRight {
    -webkit-animation: iziT-bounceInRight 0.85s ease-in-out both;
    animation: iziT-bounceInRight 0.85s ease-in-out both;
}

.iziToast.bounceInDown {
    -webkit-animation: iziT-bounceInDown 0.7s ease-in-out both;
    animation: iziT-bounceInDown 0.7s ease-in-out both;
}

.iziToast.bounceInUp {
    -webkit-animation: iziT-bounceInUp 0.7s ease-in-out both;
    animation: iziT-bounceInUp 0.7s ease-in-out both;
}

.iziToast.fadeIn {
    -webkit-animation: iziT-fadeIn 0.5s ease both;
    animation: iziT-fadeIn 0.5s ease both;
}

.iziToast.fadeInUp {
    -webkit-animation: iziT-fadeInUp 0.7s ease both;
    animation: iziT-fadeInUp 0.7s ease both;
}

.iziToast.fadeInDown {
    -webkit-animation: iziT-fadeInDown 0.7s ease both;
    animation: iziT-fadeInDown 0.7s ease both;
}

.iziToast.fadeInLeft {
    -webkit-animation: iziT-fadeInLeft 0.85s cubic-bezier(0.25, 0.8, 0.25, 1) both;
    animation: iziT-fadeInLeft 0.85s cubic-bezier(0.25, 0.8, 0.25, 1) both;
}

.iziToast.fadeInRight {
    -webkit-animation: iziT-fadeInRight 0.85s cubic-bezier(0.25, 0.8, 0.25, 1) both;
    animation: iziT-fadeInRight 0.85s cubic-bezier(0.25, 0.8, 0.25, 1) both;
}

.iziToast.flipInX {
    -webkit-animation: iziT-flipInX 0.85s cubic-bezier(0.35, 0, 0.25, 1) both;
    animation: iziT-flipInX 0.85s cubic-bezier(0.35, 0, 0.25, 1) both;
}

.iziToast.fadeOut {
    -webkit-animation: iziT-fadeOut 0.7s ease both;
    animation: iziT-fadeOut 0.7s ease both;
}

.iziToast.fadeOutDown {
    -webkit-animation: iziT-fadeOutDown 0.7s cubic-bezier(0.4, 0.45, 0.15, 0.91) both;
    animation: iziT-fadeOutDown 0.7s cubic-bezier(0.4, 0.45, 0.15, 0.91) both;
}

.iziToast.fadeOutUp {
    -webkit-animation: iziT-fadeOutUp 0.7s cubic-bezier(0.4, 0.45, 0.15, 0.91) both;
    animation: iziT-fadeOutUp 0.7s cubic-bezier(0.4, 0.45, 0.15, 0.91) both;
}

.iziToast.fadeOutLeft {
    -webkit-animation: iziT-fadeOutLeft 0.5s ease both;
    animation: iziT-fadeOutLeft 0.5s ease both;
}

.iziToast.fadeOutRight {
    -webkit-animation: iziT-fadeOutRight 0.5s ease both;
    animation: iziT-fadeOutRight 0.5s ease both;
}

.iziToast.flipOutX {
    -webkit-backface-visibility: visible !important;
    backface-visibility: visible !important;
    -webkit-animation: iziT-flipOutX 0.7s cubic-bezier(0.4, 0.45, 0.15, 0.91) both;
    animation: iziT-flipOutX 0.7s cubic-bezier(0.4, 0.45, 0.15, 0.91) both;
}

.iziToast-overlay.fadeIn {
    -webkit-animation: iziT-fadeIn 0.5s ease both;
    animation: iziT-fadeIn 0.5s ease both;
}

.iziToast-overlay.fadeOut {
    -webkit-animation: iziT-fadeOut 0.7s ease both;
    animation: iziT-fadeOut 0.7s ease both;
}

/*custom styles*/
.iziToast.iziToast-success {
    border-color: #000;
    background-color: #2bd396;
    color: #fff;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, .3);
}

.iziToast.iziToast-error {
    border-color: #000000;
    background-color: #ff4a46;
    color: #fff;
}

.iziToast.iziToast-theme-dark .iziToast-message {
    color: rgba(255, 255, 255, 1);
    font-weight: 400;
    font-size: 15px;
    font-family: 'Exo 2';
}


@-webkit-keyframes iziT-revealIn {
    0% {
        opacity: 0;
        -webkit-transform: scale3d(0.3, 0.3, 1);
    }
    100% {
        opacity: 1;
    }
}

@-moz-keyframes iziT-revealIn {
    0% {
        opacity: 0;
        -moz-transform: scale3d(0.3, 0.3, 1);
    }
    100% {
        opacity: 1;
    }
}

@-webkit-keyframes iziT-slideIn {
    0% {
        opacity: 0;
        -webkit-transform: translateX(50px);
    }
    100% {
        opacity: 1;
        -webkit-transform: translateX(0);
    }
}

@-moz-keyframes iziT-slideIn {
    0% {
        opacity: 0;
        -moz-transform: translateX(50px);
    }
    100% {
        opacity: 1;
        -moz-transform: translateX(0);
    }
}

@-webkit-keyframes iziT-bounceInLeft {
    0% {
        opacity: 0;
        -webkit-transform: translateX(280px);
    }
    50% {
        opacity: 1;
        -webkit-transform: translateX(-20px);
    }
    70% {
        -webkit-transform: translateX(10px);
    }
    100% {
        -webkit-transform: translateX(0);
    }
}

@-webkit-keyframes iziT-bounceInRight {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-280px);
    }
    50% {
        opacity: 1;
        -webkit-transform: translateX(20px);
    }
    70% {
        -webkit-transform: translateX(-10px);
    }
    100% {
        -webkit-transform: translateX(0);
    }
}

@-webkit-keyframes iziT-bounceInDown {
    0% {
        opacity: 0;
        -webkit-transform: translateY(-200px);
    }
    50% {
        opacity: 1;
        -webkit-transform: translateY(10px);
    }
    70% {
        -webkit-transform: translateY(-5px);
    }
    100% {
        -webkit-transform: translateY(0);
    }
}

@-webkit-keyframes iziT-bounceInUp {
    0% {
        opacity: 0;
        -webkit-transform: translateY(200px);
    }
    50% {
        opacity: 1;
        -webkit-transform: translateY(-10px);
    }
    70% {
        -webkit-transform: translateY(5px);
    }
    100% {
        -webkit-transform: translateY(0);
    }
}

@-webkit-keyframes iziT-fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@-webkit-keyframes iziT-fadeInUp {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
    }
    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}

@-webkit-keyframes iziT-fadeInDown {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
    }
    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}

@-webkit-keyframes iziT-fadeInLeft {
    from {
        opacity: 0;
        -webkit-transform: translate3d(300px, 0, 0);
        transform: translate3d(300px, 0, 0);
    }
    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}

@-webkit-keyframes iziT-fadeInRight {
    from {
        opacity: 0;
        -webkit-transform: translate3d(-300px, 0, 0);
        transform: translate3d(-300px, 0, 0);
    }
    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}

@-webkit-keyframes iziT-flipInX {
    from {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
        transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
        opacity: 0;
    }
    40% {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
        transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    }
    60% {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
        transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
        opacity: 1;
    }
    80% {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
        transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
    }
    to {
        -webkit-transform: perspective(400px);
        transform: perspective(400px);
    }
}

@-webkit-keyframes iziT-fadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

@-webkit-keyframes iziT-fadeOutDown {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
    }
}

@-webkit-keyframes iziT-fadeOutUp {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
    }
}

@-webkit-keyframes iziT-fadeOutLeft {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
        -webkit-transform: translate3d(-200px, 0, 0);
        transform: translate3d(-200px, 0, 0);
    }
}

@-webkit-keyframes iziT-fadeOutRight {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
        -webkit-transform: translate3d(200px, 0, 0);
        transform: translate3d(200px, 0, 0);
    }
}

@-webkit-keyframes iziT-flipOutX {
    from {
        -webkit-transform: perspective(400px);
        transform: perspective(400px);
    }
    30% {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
        transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
        opacity: 1;
    }
    to {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
        transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
        opacity: 0;
    }
}

@-moz-keyframes iziT-revealIn {
    0% {
        opacity: 0;
        transform: scale3d(0.3, 0.3, 1);
    }
    100% {
        opacity: 1;
    }
}

@-webkit-keyframes iziT-revealIn {
    0% {
        opacity: 0;
        transform: scale3d(0.3, 0.3, 1);
    }
    100% {
        opacity: 1;
    }
}

@-o-keyframes iziT-revealIn {
    0% {
        opacity: 0;
        transform: scale3d(0.3, 0.3, 1);
    }
    100% {
        opacity: 1;
    }
}

@keyframes iziT-revealIn {
    0% {
        opacity: 0;
        transform: scale3d(0.3, 0.3, 1);
    }
    100% {
        opacity: 1;
    }
}

@-moz-keyframes iziT-slideIn {
    0% {
        opacity: 0;
        transform: translateX(50px);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@-webkit-keyframes iziT-slideIn {
    0% {
        opacity: 0;
        transform: translateX(50px);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@-o-keyframes iziT-slideIn {
    0% {
        opacity: 0;
        transform: translateX(50px);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes iziT-slideIn {
    0% {
        opacity: 0;
        transform: translateX(50px);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@-moz-keyframes iziT-bounceInLeft {
    0% {
        opacity: 0;
        transform: translateX(280px);
    }
    50% {
        opacity: 1;
        transform: translateX(-20px);
    }
    70% {
        transform: translateX(10px);
    }
    100% {
        transform: translateX(0);
    }
}

@-webkit-keyframes iziT-bounceInLeft {
    0% {
        opacity: 0;
        transform: translateX(280px);
    }
    50% {
        opacity: 1;
        transform: translateX(-20px);
    }
    70% {
        transform: translateX(10px);
    }
    100% {
        transform: translateX(0);
    }
}

@-o-keyframes iziT-bounceInLeft {
    0% {
        opacity: 0;
        transform: translateX(280px);
    }
    50% {
        opacity: 1;
        transform: translateX(-20px);
    }
    70% {
        transform: translateX(10px);
    }
    100% {
        transform: translateX(0);
    }
}

@keyframes iziT-bounceInLeft {
    0% {
        opacity: 0;
        transform: translateX(280px);
    }
    50% {
        opacity: 1;
        transform: translateX(-20px);
    }
    70% {
        transform: translateX(10px);
    }
    100% {
        transform: translateX(0);
    }
}

@-moz-keyframes iziT-bounceInRight {
    0% {
        opacity: 0;
        transform: translateX(-280px);
    }
    50% {
        opacity: 1;
        transform: translateX(20px);
    }
    70% {
        transform: translateX(-10px);
    }
    100% {
        transform: translateX(0);
    }
}

@-webkit-keyframes iziT-bounceInRight {
    0% {
        opacity: 0;
        transform: translateX(-280px);
    }
    50% {
        opacity: 1;
        transform: translateX(20px);
    }
    70% {
        transform: translateX(-10px);
    }
    100% {
        transform: translateX(0);
    }
}

@-o-keyframes iziT-bounceInRight {
    0% {
        opacity: 0;
        transform: translateX(-280px);
    }
    50% {
        opacity: 1;
        transform: translateX(20px);
    }
    70% {
        transform: translateX(-10px);
    }
    100% {
        transform: translateX(0);
    }
}

@keyframes iziT-bounceInRight {
    0% {
        opacity: 0;
        transform: translateX(-280px);
    }
    50% {
        opacity: 1;
        transform: translateX(20px);
    }
    70% {
        transform: translateX(-10px);
    }
    100% {
        transform: translateX(0);
    }
}

@-moz-keyframes iziT-bounceInDown {
    0% {
        opacity: 0;
        transform: translateY(-200px);
    }
    50% {
        opacity: 1;
        transform: translateY(10px);
    }
    70% {
        transform: translateY(-5px);
    }
    100% {
        transform: translateY(0);
    }
}

@-webkit-keyframes iziT-bounceInDown {
    0% {
        opacity: 0;
        transform: translateY(-200px);
    }
    50% {
        opacity: 1;
        transform: translateY(10px);
    }
    70% {
        transform: translateY(-5px);
    }
    100% {
        transform: translateY(0);
    }
}

@-o-keyframes iziT-bounceInDown {
    0% {
        opacity: 0;
        transform: translateY(-200px);
    }
    50% {
        opacity: 1;
        transform: translateY(10px);
    }
    70% {
        transform: translateY(-5px);
    }
    100% {
        transform: translateY(0);
    }
}

@keyframes iziT-bounceInDown {
    0% {
        opacity: 0;
        transform: translateY(-200px);
    }
    50% {
        opacity: 1;
        transform: translateY(10px);
    }
    70% {
        transform: translateY(-5px);
    }
    100% {
        transform: translateY(0);
    }
}

@-moz-keyframes iziT-bounceInUp {
    0% {
        opacity: 0;
        transform: translateY(200px);
    }
    50% {
        opacity: 1;
        transform: translateY(-10px);
    }
    70% {
        transform: translateY(5px);
    }
    100% {
        transform: translateY(0);
    }
}

@-webkit-keyframes iziT-bounceInUp {
    0% {
        opacity: 0;
        transform: translateY(200px);
    }
    50% {
        opacity: 1;
        transform: translateY(-10px);
    }
    70% {
        transform: translateY(5px);
    }
    100% {
        transform: translateY(0);
    }
}

@-o-keyframes iziT-bounceInUp {
    0% {
        opacity: 0;
        transform: translateY(200px);
    }
    50% {
        opacity: 1;
        transform: translateY(-10px);
    }
    70% {
        transform: translateY(5px);
    }
    100% {
        transform: translateY(0);
    }
}

@keyframes iziT-bounceInUp {
    0% {
        opacity: 0;
        transform: translateY(200px);
    }
    50% {
        opacity: 1;
        transform: translateY(-10px);
    }
    70% {
        transform: translateY(5px);
    }
    100% {
        transform: translateY(0);
    }
}

@-moz-keyframes iziT-fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@-webkit-keyframes iziT-fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@-o-keyframes iziT-fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes iziT-fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@-moz-keyframes iziT-fadeInUp {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
    }
    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}

@-webkit-keyframes iziT-fadeInUp {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
    }
    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}

@-o-keyframes iziT-fadeInUp {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
    }
    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}

@keyframes iziT-fadeInUp {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
    }
    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}

@-moz-keyframes iziT-fadeInDown {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
    }
    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}

@-webkit-keyframes iziT-fadeInDown {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
    }
    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}

@-o-keyframes iziT-fadeInDown {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
    }
    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}

@keyframes iziT-fadeInDown {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
    }
    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}

@-moz-keyframes iziT-fadeInLeft {
    from {
        opacity: 0;
        -webkit-transform: translate3d(300px, 0, 0);
        transform: translate3d(300px, 0, 0);
    }
    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}

@-webkit-keyframes iziT-fadeInLeft {
    from {
        opacity: 0;
        -webkit-transform: translate3d(300px, 0, 0);
        transform: translate3d(300px, 0, 0);
    }
    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}

@-o-keyframes iziT-fadeInLeft {
    from {
        opacity: 0;
        -webkit-transform: translate3d(300px, 0, 0);
        transform: translate3d(300px, 0, 0);
    }
    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}

@keyframes iziT-fadeInLeft {
    from {
        opacity: 0;
        -webkit-transform: translate3d(300px, 0, 0);
        transform: translate3d(300px, 0, 0);
    }
    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}

@-moz-keyframes iziT-fadeInRight {
    from {
        opacity: 0;
        -webkit-transform: translate3d(-300px, 0, 0);
        transform: translate3d(-300px, 0, 0);
    }
    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}

@-webkit-keyframes iziT-fadeInRight {
    from {
        opacity: 0;
        -webkit-transform: translate3d(-300px, 0, 0);
        transform: translate3d(-300px, 0, 0);
    }
    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}

@-o-keyframes iziT-fadeInRight {
    from {
        opacity: 0;
        -webkit-transform: translate3d(-300px, 0, 0);
        transform: translate3d(-300px, 0, 0);
    }
    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}

@keyframes iziT-fadeInRight {
    from {
        opacity: 0;
        -webkit-transform: translate3d(-300px, 0, 0);
        transform: translate3d(-300px, 0, 0);
    }
    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}

@-moz-keyframes iziT-flipInX {
    from {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
        transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
        opacity: 0;
    }
    40% {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
        transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    }
    60% {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
        transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
        opacity: 1;
    }
    80% {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
        transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
    }
    to {
        -webkit-transform: perspective(400px);
        transform: perspective(400px);
    }
}

@-webkit-keyframes iziT-flipInX {
    from {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
        transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
        opacity: 0;
    }
    40% {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
        transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    }
    60% {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
        transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
        opacity: 1;
    }
    80% {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
        transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
    }
    to {
        -webkit-transform: perspective(400px);
        transform: perspective(400px);
    }
}

@-o-keyframes iziT-flipInX {
    from {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
        transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
        opacity: 0;
    }
    40% {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
        transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    }
    60% {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
        transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
        opacity: 1;
    }
    80% {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
        transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
    }
    to {
        -webkit-transform: perspective(400px);
        transform: perspective(400px);
    }
}

@keyframes iziT-flipInX {
    from {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
        transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
        opacity: 0;
    }
    40% {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
        transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    }
    60% {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
        transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
        opacity: 1;
    }
    80% {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
        transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
    }
    to {
        -webkit-transform: perspective(400px);
        transform: perspective(400px);
    }
}

@-moz-keyframes iziT-fadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

@-webkit-keyframes iziT-fadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

@-o-keyframes iziT-fadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

@keyframes iziT-fadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

@-moz-keyframes iziT-fadeOutDown {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
    }
}

@-webkit-keyframes iziT-fadeOutDown {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
    }
}

@-o-keyframes iziT-fadeOutDown {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
    }
}

@keyframes iziT-fadeOutDown {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
    }
}

@-moz-keyframes iziT-fadeOutUp {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
    }
}

@-webkit-keyframes iziT-fadeOutUp {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
    }
}

@-o-keyframes iziT-fadeOutUp {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
    }
}

@keyframes iziT-fadeOutUp {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
    }
}

@-moz-keyframes iziT-fadeOutLeft {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
        -webkit-transform: translate3d(-200px, 0, 0);
        transform: translate3d(-200px, 0, 0);
    }
}

@-webkit-keyframes iziT-fadeOutLeft {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
        -webkit-transform: translate3d(-200px, 0, 0);
        transform: translate3d(-200px, 0, 0);
    }
}

@-o-keyframes iziT-fadeOutLeft {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
        -webkit-transform: translate3d(-200px, 0, 0);
        transform: translate3d(-200px, 0, 0);
    }
}

@keyframes iziT-fadeOutLeft {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
        -webkit-transform: translate3d(-200px, 0, 0);
        transform: translate3d(-200px, 0, 0);
    }
}

@-moz-keyframes iziT-fadeOutRight {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
        -webkit-transform: translate3d(200px, 0, 0);
        transform: translate3d(200px, 0, 0);
    }
}

@-webkit-keyframes iziT-fadeOutRight {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
        -webkit-transform: translate3d(200px, 0, 0);
        transform: translate3d(200px, 0, 0);
    }
}

@-o-keyframes iziT-fadeOutRight {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
        -webkit-transform: translate3d(200px, 0, 0);
        transform: translate3d(200px, 0, 0);
    }
}

@keyframes iziT-fadeOutRight {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
        -webkit-transform: translate3d(200px, 0, 0);
        transform: translate3d(200px, 0, 0);
    }
}

@-moz-keyframes iziT-flipOutX {
    from {
        -webkit-transform: perspective(400px);
        transform: perspective(400px);
    }
    30% {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
        transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
        opacity: 1;
    }
    to {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
        transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
        opacity: 0;
    }
}

@-webkit-keyframes iziT-flipOutX {
    from {
        -webkit-transform: perspective(400px);
        transform: perspective(400px);
    }
    30% {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
        transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
        opacity: 1;
    }
    to {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
        transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
        opacity: 0;
    }
}

@-o-keyframes iziT-flipOutX {
    from {
        -webkit-transform: perspective(400px);
        transform: perspective(400px);
    }
    30% {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
        transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
        opacity: 1;
    }
    to {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
        transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
        opacity: 0;
    }
}

@keyframes iziT-flipOutX {
    from {
        -webkit-transform: perspective(400px);
        transform: perspective(400px);
    }
    30% {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
        transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
        opacity: 1;
    }
    to {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
        transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
        opacity: 0;
    }
}
